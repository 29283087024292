* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Define Rajdhani Light */
@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/RAJDHANI-LIGHT.TTF') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Define Rajdhani Regular */
@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/RAJDHANI-REGULAR.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Define Rajdhani Medium */
@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/RAJDHANI-MEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Define Rajdhani SemiBold */
@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/RAJDHANI-SEMIBOLD.TTF') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Define Rajdhani Bold */
@font-face {
  font-family: 'Rajdhani';
  src: url('../assets/fonts/RAJDHANI-BOLD.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
}


body {
  font-family: 'Rajdhani', sans-serif; /* Add your custom font here */
  font-weight: 400;
}

h1 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700; /* Bold */
  color: #0b6565;
}

h2 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500; /* Bold */
  color: #0b6565;
}

h3 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600; /* Bold */
  color: #0b6565;
}

h4 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500; /* Bold */
  color: #4E4F4F;
}

h5 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600; /* Bold */
  color: #4E4F4F;
}
.semibold {
    font-weight: 600; /* Bold */
    margin-bottom: 50px;
    margin-top: 40px;
}
p {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 300; /* Light */
}

.landing-container {
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100vh;
  background-image: url('../images/background-image.svg'); /* Path to your background image */
  background-size: stretch;
  background-repeat: no-repeat;
  background-position: right;
  text-align: left;
  color: white;
}

.content {
    padding-top: 60px;
padding-left: 20vh;
}

.content h1 {
  font-size: 3rem;
  margin-top: 120px;
}

.content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.content h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.services {
  list-style: none;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.services li {
  margin-bottom: 10px;
}

.content h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.content h4 {
  font-size: 1.5rem;
}

.consultation-btn {
  background-color: #0b6565;
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
   font-weight: 600; 
}

.consultation-btn:hover {
  background-color: #0b6565;
}
.logo {
  max-width: 400px; /* Adjust size as necessary */
  margin-bottom: 20px; /* Add some space between the logo and text */
}
.backgroundImage {
  display: none;
}

@media (max-width: 768px) {
  .landing-container {
     background-image: none
  }
.semibold {
   margin-top: 20px;
    margin-bottom: 10px;
}
  .backgroundImage {
  display: flex;
   width: 100%;
  height: auto;
  margin-top: -100px;
  margin-bottom: 20px;
  /* width: 100vh;
  max-width: 400px;  */
}

    .content {
    padding-top: 60px;
padding-left: 20px;
}
      .logo {
    max-width: 280px; /* Reduce size for smaller screens */
  }

  .content h1 {
    font-size: 2rem;
     margin-top: 20px;
  }

  .content h2 {
    font-size: 1.2rem;
  }
.content h3 {
  margin-bottom: 0px;
}
    .content h4 {
    font-size: 1rem;
  }

  .services {
    font-size: 1.2rem;
  }

  .content h3 {
    font-size: 1.2rem;
  }

  .consultation-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
